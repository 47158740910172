* {
  box-sizing: border-box;
}
html, body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
html {
  font-size: 62.5%;
  font-family: "ＭＳ ゴシック", monospace, serif;
}
body {
  font-size: 1.6rem;
}
#root {
  height: 100%;
  width: 100%;
}
ul {
  margin: 0px;
}

li > caption {
  margin: 5px;
  white-space: nowrap;
  opacity: 0.7;
}

li.main {
  font-weight: bold;
}

.icon-link {
  padding: 5px;
  min-width: 90px;
}

.link-icon {
  height: var(--bh-size, 40px);
  width: var(--hb-size, 40px);
}
.link-text {
  font-size: 1.2rem;
}

.npm {
  margin-top: 10px;
}
.npm-ex {
  padding: 5px 0px 0px 10px;
  line-height: 1.8rem;
  font-size: 1.5rem;
}

.screen {
  max-width: 100%;
  max-height: 100%;
}

.bh-code + .bh-code {
  margin-top: 10px;
}